@font-face {
  font-family: 'Wotfard';
  src: url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-thin-webfont.ttf') format('truetype'),
  url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-thin-webfont.woff2') format('woff2');
  font-weight: 100;
}
@font-face {
  font-family: 'Wotfard';
  src: url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-light-webfont.ttf') format('truetype'),
  url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-light-webfont.woff2') format('woff2');
  font-weight: 200;
}
@font-face {
  font-family: 'Wotfard';
  src: url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-extralight-webfont.ttf') format('truetype'),
  url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-extralight-webfont.woff2') format('woff2');
  font-weight: 300;
}
@font-face {
  font-family: 'Wotfard';
  src: url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-regular-webfont.ttf') format('truetype'),
  url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-regular-webfont.woff2') format('woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'Wotfard';
  src: url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-medium-webfont.ttf') format('truetype'),
  url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-medium-webfont.woff2') format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'Wotfard';
  src: url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-semibold-webfont.ttf') format('truetype'),
  url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-semibold-webfont.woff2') format('woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'Wotfard';
  src: url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-bold-webfont.ttf') format('truetype'),
  url('https://static-assets-cdn.aws.posturize.app/fonts/wotfard-bold-webfont.woff2') format('woff2');
  font-weight: 700;
}
* {
  font-family: Wotfard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

body {
  margin: 0;
  font-family: Wotfard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
